const DownVote = () => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 7.75H9.25V7V1C9.25 0.870436 9.21008 0.820745 9.19467 0.80533C9.17925 0.789915 9.12956 0.75 9 0.75H5C4.87044 0.75 4.82075 0.789914 4.80533 0.80533C4.78991 0.820745 4.75 0.870436 4.75 1V7V7.75H4H1C0.850183 7.75 0.795699 7.7804 0.785688 7.78705C0.775594 7.80287 0.75 7.85925 0.75 8C0.75 8.15034 0.80558 8.24492 1.13033 8.56967L1.15355 8.59289L1.17464 8.61804L6.35345 14.7928C6.55353 14.9925 6.66926 15.1031 6.77853 15.176C6.86383 15.2328 6.92144 15.25 7 15.25C7.07856 15.25 7.13617 15.2328 7.22147 15.176C7.33074 15.1031 7.44647 14.9925 7.64655 14.7928L12.8254 8.61804L12.8465 8.59289L12.8697 8.56967C13.1944 8.24492 13.25 8.15034 13.25 8C13.25 7.85925 13.2244 7.80288 13.2143 7.78705C13.2043 7.7804 13.1498 7.75 13 7.75H10ZM0.789698 7.78203C0.789559 7.78189 0.78824 7.78316 0.786162 7.78631C0.788799 7.78376 0.789838 7.78218 0.789698 7.78203ZM13.2103 7.78203C13.2102 7.78219 13.2112 7.78376 13.2138 7.78631C13.2118 7.78316 13.2104 7.78188 13.2103 7.78203Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default DownVote;
