const UpVote = () => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 8.25H9.25V9V15C9.25 15.1296 9.21008 15.1793 9.19467 15.1947C9.17925 15.2101 9.12956 15.25 9 15.25H5C4.87044 15.25 4.82075 15.2101 4.80533 15.1947C4.78991 15.1793 4.75 15.1296 4.75 15V9V8.25H4H1C0.850183 8.25 0.795699 8.2196 0.785688 8.21295C0.775594 8.19713 0.75 8.14075 0.75 8C0.75 7.84966 0.80558 7.75508 1.13033 7.43033L1.15355 7.40711L1.17464 7.38196L6.35345 1.20723C6.55353 1.00748 6.66926 0.89688 6.77853 0.824038C6.86383 0.767171 6.92144 0.75 7 0.75C7.07856 0.75 7.13617 0.767171 7.22147 0.824038C7.33074 0.89688 7.44647 1.00748 7.64655 1.20723L12.8254 7.38196L12.8465 7.40711L12.8697 7.43033C13.1944 7.75508 13.25 7.84966 13.25 8C13.25 8.14075 13.2244 8.19712 13.2143 8.21295C13.2043 8.2196 13.1498 8.25 13 8.25H10ZM0.789698 8.21797C0.789559 8.21811 0.78824 8.21684 0.786162 8.21369C0.788799 8.21624 0.789838 8.21782 0.789698 8.21797ZM13.2103 8.21797C13.2102 8.21781 13.2112 8.21624 13.2138 8.21369C13.2118 8.21684 13.2104 8.21812 13.2103 8.21797Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default UpVote;
